/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const DefaultImage = [
  {
    property: "og:image",
    content:"/static/dfdpng/dfd_2.png",
    //"https://res.cloudinary.com/airbnb-clone-dusky/image/upload/v1636624600/dfd_wgrbjt.png",
  },
  {
    property: "og:image:type",
    content: "image/png",
  },
  {
    property: "og:image:width",
    content: "512",
  },
  {
    property: "og:image:height",
    content: "512",
  },
]

function SEO({ description, lang, meta, title, imageUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const image = imageUrl
    ? [{ property: "og:image", content: imageUrl }]
    : DefaultImage

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: "Donate for Dharma",
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: "donatefordharma.org",
        },
        {
          property: "og:url",
          content: "donatefordharma.org",
        },
        {
          name: "author",
          content: "donatefordharma.org",
        },
        {
          name: "keywords",
          content: "temple ngo, research ngo, education ngo, animal right ngo, jeev seva, culture ngo, india ngo, dharma, prachar, dharm, dharma ngo, donate ngo, donate dharm, daan, environment ngo, crowdfunding india, crowdfunding, crowdfunding website, crowdsourcing, 80G, donate 80G ngos, 80G ngo",
        },
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@tofi_in",
        },
        {
          name: "twitter:title",
          content: metaTitle,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ].concat(meta, image)}
      link={[
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/static/dfd@0.1x.png",///apple-touch-icon.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/static/dfd@0.1x.png",///favicon-32x32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/static/dfdpng/dfd@0.06x.png",//"/favicon-16x16.png",
        },
        {
          rel: "manifest",
          href: "/site.webmanifest",
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  description: "Find dharmik organizations and donate",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
