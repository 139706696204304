/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Navbar from "./Navbar"

import "./layout.css"
import NetlifyLogo from "../images/netlify.svg"
import FooterLink from "./FooterLink"

const getFooterLinks = () => [
  {
    href:
    "mailto:contact@donatefordharma.org",
    title: "Contact Us",
  },
]

const darmikOrgs = [
  {
    title: 'Culture Org',
    slug: '/categories/culture'
  },
  {
    title: 'Dharm-Prachar Org',
    slug: '/categories/dharm-prachar'
  },
  {
    title: 'Education Org',
    slug: '/categories/education'
  },
  {
    title: 'Environment',
    slug: '/categories/environment'
  },
  {
    title: 'Gram Vikas Org',
    slug: '/categories/gram-vikas'
  },
  {
    title: 'Jeev Seva Org',
    slug: '/categories/jeev-seva'
  },
  {
    title: 'Temple Org',
    slug: '/categories/temple'
  },
  {
    title: 'Research Org',
    slug: '/categories/research'
  },
]

const Layout = ({ children, contentClassName }) => {
  const footerLinks = getFooterLinks()

  return (
    <div className="flex flex-col min-h-screen antialiased">
      <Navbar />
      <main className={`flex-grow pt-16 ${contentClassName}`}>{children}</main>
      <footer class="bg-orange-200">
        <div class="container mx-auto px-6 pt-10">
          
          <div class="flex flex-wrap">
          <div class="w-full md:w-1/6 text-center md:text-center">
            </div>
          <div class="w-full md:w-1/4 text-center md:text-left">
              <h5 class="uppercase mb-3 font-bold"><a href="https://donatefordharma.org/">Donate For Dharma</a></h5>
              <ul class="mb-4">
                <li class="mt-2">
                  <a href="/contribute" class="hover:underline text-gray-600 hover:text-red-500">About Us</a>
                </li>
                <li class="mt-2">
                  <a href="https://airtable.com/shrq6uTUQeyZAtvmR"  target="_blank" class="hover:underline text-gray-600 hover:text-red-500">Join our editorial team</a>
                </li>
                
                
                <li class="mt-2">
                  <a href="https://airtable.com/shru2OXtkpN0EkOL0" target="_blank"  class="hover:underline text-gray-600 hover:text-red-500">Submit Dharmik Organization </a>
                </li>
                <li class="mt-2">
                  <a href="mailto:contact@donatefordharma.org" class="hover:underline text-gray-600 hover:text-red-500">Contact Us</a>
                </li>
                <li class="mt-2">
                  <a href="https://blog.templesofindia.org"  target="_blank"  class="hover:underline text-gray-600 hover:text-red-500">Temple Blog</a>
                </li>
                <li class="mt-2">
                  <a href="https://templesofindia.org/" target="_blank"  class="hover:underline text-gray-600 hover:text-red-500">Temples of India</a>
                </li>
                
              </ul>
            </div>
            <div class="md:w-1/6 text-center md:text-left"></div>
            <div class="w-full md:w-1/4 text-center md:text-left">
              <h5 class="uppercase mb-3 font-bold"><a href="https://donatefordharma.org/organizations">Dharmik Organizations</a></h5>
              <ul class="mb-4">
                {darmikOrgs.map(dOrg => (
                  <li class="mt-2" key={dOrg.slug}>
                    <a href={dOrg.slug} class="hover:underline text-gray-600 hover:text-red-500">{dOrg.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <footer className="text-gray-800 bg-orange-200 text-center md:text-left md:px-6 md:flex items-center justify-between">
      <div class="md:w-1/7 "></div>
        <div className="text-center ">
          ©DonateForDharma 2021. &nbsp;&nbsp;&nbsp;&nbsp;
          {footerLinks.map((l, i) => (
            <FooterLink
              key={l.title}
              href={l.href}
              title={l.title}
              divider={i !== footerLinks.length - 1}
            />
          ))}
        </div>
        <div className="pt-3 sm:pt-0 flex ">
          <a href="https://www.netlify.com">
            <img className="h-12" src={NetlifyLogo} alt="Deploys by Netlify" />
          </a>
        </div>
      </footer>
     
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  contentClassName: PropTypes.string,
}

export default Layout
